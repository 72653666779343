<template>
  <div class="courseupload">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item>课程资料</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.title}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.type}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
        <el-image style="width: 100px; height: 100px;margin-right:10px;border-radius:5px"
        v-for="(item,index) in StudentFile" :key="index"
                  :src="item"
                  :preview-src-list="StudentFile">
        </el-image>
      </div>
    <div  v-show="TeacherFile.length>0">
      <el-divider></el-divider>
      <p class="tech">教师批改结果</p>
      <el-image style="width: 100px; height: 100px;margin-right:10px;border-radius:5px"
    v-for="(item,index) in TeacherFile" :key="index"
              :src="item"
              :preview-src-list="TeacherFile">
    </el-image>
    </div>

  </div>
</template>

<script>
import { get_picture_result } from '@/api/classesinfo'
export default {
  name: 'coursemater_courseuploadresult',
  data () {
    return {
      prames:{},
      StudentFile:[],
      TeacherFile:[]
    };
  },
  created () {
    var prames = sessionStorage.getItem('stu_coursemater_query')? JSON.parse(sessionStorage.getItem('stu_coursemater_query')):{}
    this.prames=prames;
    this.getInit()
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getInit () {
      let prames = {
        userid: this.prames.userid,
        DBType:this.prames.DBType,
        OprId:this.prames.OprId,
        LessonID:this.prames.LessonID,
        UploadType:this.prames.UploadType,
      }
      let res = await get_picture_result(prames)
      if (res.flag == '1') {
        this.StudentFile = res.datainfo.StudentFile;
        this.TeacherFile = res.datainfo.TeacherFile;
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
  },

}
</script>
<style  lang="scss" scoped>
.tech{
  margin-bottom: 20px;
}
</style>